import { FullScreenLoader } from 'components/loader';
import FormCard from 'design-system/form-card';
import SidebarSlide from 'design-system/sidebar-slide';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDeviceTasksQuery } from 'store/devices/devicesApi';
import { selectDeviceSelected, selectDeviceSelectedSerialNumber } from 'store/devices/devicesSlice';
import { useAddRecipeMutation, useUpdateRecetaMutation } from 'store/recetas/recetasApi';
import { recetasActions, selectRecetaEditing } from 'store/recetas/recetasSlice';
import { Recipe } from 'utils/device/event/diet';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';
import TablaReceta from '../tabla-receta';
import RecetaForm from './receta-form';
import TablaAlimentos from './tabla-alimentos';
import store from 'store/store';
import { isDeviceVersionAtLeast, VERSION } from 'utils/helpers/validation';
import { selectRecetas } from 'selectors/recetas.selectors';

function ModalAgregarReceta({
  isOpen,
  onClose = () => {},
  setIsSavingData = () => {},
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsSavingData?: (isSavingData: boolean) => void;
}) {
  const dispatch = useDispatch();

  const deviceSelected = useSelector(selectDeviceSelected);
  const isDeviceVersionSix = isDeviceVersionAtLeast(VERSION.SIX, deviceSelected);
  const recetaEditing = useSelector(selectRecetaEditing);
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';
  const getDeviceTasks = useGetDeviceTasksQuery({ serialNumber });

  // Obtener todos los sortkeys de las recetas
  const recetas = useSelector(selectRecetas) || [];
  const sortKeys = recetas.filter((receta) => !!receta.sortKey).map((receta) => receta.sortKey || 1);

  const [onAddRecipe, { isLoading: isAddRecipeLoading }] = useAddRecipeMutation();

  const [onUpdateReceta, { isLoading: isUpdateRecetaLoading }] = useUpdateRecetaMutation();

  // Editing Recipe form state
  const methods = useForm<Recipe>({
    defaultValues: recetaEditing,
  });
  const { watch } = methods;
  const [name, mixingTime, realHeadCount, unloadGuide, type] = watch([
    'name',
    'mixingTime',
    'realHeadCount',
    'unloadGuide',
    'type',
  ]);
  const canGuardar = name !== '';

  const handleClose = () => {
    dispatch(recetasActions.resetReceta());
    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recetaNombre = useMemo(() => recetaEditing.name, [isOpen]); // deshabilitar warning porque es para que no se cambie el titulo del modal con cada cambio del nombre de la receta

  const onSave = async () => {
    setIsSavingData(true);

    const recetaEditingFromStore = store.getState().recetas.recetaEditing;

    const isNewReceta = !recetaNombre;

    const newReceta = parseRecetaBeforeSending({
      ...recetaEditingFromStore,
      name,
      mixingTime,
      realHeadCount,
      unloadGuide,
      type,
      ...(isDeviceVersionSix ? { sortKey: isNewReceta ? sortKeys?.length + 1 : recetaEditing.sortKey } : {}),
    });

    const promise = !recetaNombre
      ? onAddRecipe({
          receta: newReceta,
          serialNumber,
        })
      : onUpdateReceta({
          receta: newReceta,
          serialNumber,
        });

    const onSuccess = () => {
      getDeviceTasks.refetch();
      handleClose();
    };

    await mutateWithToast(promise, onSuccess, {
      pending: 'Guardando receta...',
      success: 'Receta guardada correctamente.',
      error: 'Error al guardar la receta.',
    });
  };

  /*   useEffect(() => {
    //reset form on first render (when modal is opened)
    if (isOpen) {
      methods.reset(recetaEditing);
    }
  }, [isOpen, methods, recetaEditing]); */

  return (
    <SidebarSlide
      isOpen={isOpen}
      onClose={handleClose}
      title={!recetaNombre ? 'Nueva receta' : `Editar "${recetaNombre}"`}
      actions={[
        {
          autoFocus: false,
          onClick: handleClose,
          text: 'Cancelar',
          variant: 'text-danger',
        },
        {
          autoFocus: false,
          onClick: onSave,
          text: 'Guardar',
          variant: 'fill',
          disabled: !canGuardar,
        },
      ]}
    >
      {(isUpdateRecetaLoading || isAddRecipeLoading) && <FullScreenLoader />}
      <FormProvider {...methods}>
        <FormCard
          items={[
            {
              value: <RecetaForm editingEnabled />,
            },
            {
              value: <TablaAlimentos />,
            },
            {
              value: <TablaReceta receta={recetaEditing} editingEnabled />,
            },
          ]}
        />
      </FormProvider>
    </SidebarSlide>
  );
}
export default ModalAgregarReceta;

function parseRecetaBeforeSending(receta: Recipe): Recipe {
  let recetaToSend = {
    ...receta,
    mixingTime: receta.mixingTime || 0,
    realHeadCount: receta.realHeadCount || 0,
    ingredients: receta.ingredients
      .filter((ingredient) => !ingredient.deleted)
      .map(({ nuevo, ...ingrediente }) => ({ ...ingrediente })),
  };
  if (receta.unloadGuide) {
    const { lots, ...guide } = receta.unloadGuide;
    recetaToSend.unloadGuide = { ...guide };
  }
  return recetaToSend;
}
