import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateUnloadGuideMutation } from 'store/descargas/descargasApi';
import { selectDeviceSelectedSerialNumber } from 'store/devices/devicesSlice';
import { UnloadGuide } from 'utils/device/lot';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';

const MIN_SORT_KEY = 1;

export function DescargaOrden({ descarga, descargas }: { descarga: UnloadGuide; descargas: UnloadGuide[] }) {
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';

  const [onUpdateUnloadGuide] = useUpdateUnloadGuideMutation();

  // Get all sort keys
  const sortKeys = descargas.filter((descarga) => !!descarga.sortKey).map((descarga) => descarga.sortKey || 1);
  // Has sort key?
  const hasSortKey = descarga.sortKey !== undefined;
  // Get this descarga sort key
  const thisSortKey = descarga.sortKey || 0;

  // Can sort up if there are no sort keys or if it has no sort key or if it's greater than the minimum sort key
  const canSortUp = !sortKeys?.length || !hasSortKey || thisSortKey > MIN_SORT_KEY;
  // Can sort down if there are no sort keys or if it has no sort key or if it's less than the maximum sort key
  const canSortDown = !sortKeys?.length || !hasSortKey || thisSortKey < Math.max(...sortKeys);

  const onSortUp = useCallback(async () => {
    // The next order for this descarga is: if it has a sort key then it's the previous sort key, otherwise it's sortKeys?.length + 1
    const nextOrder = hasSortKey ? thisSortKey - 1 : sortKeys?.length + 1;

    // If there's a sort key that's the same as the next order, then we need to update its descarga to the next order
    const nextOrderDescarga = descargas.find((descarga) => descarga.sortKey === nextOrder);
    if (nextOrderDescarga) {
      // If nextOrderDescarga has a 'lots' property, remove it
      const { lots, ...descargaProps } = nextOrderDescarga;
      const promise = onUpdateUnloadGuide({
        descarga: {
          ...descargaProps,
          sortKey: thisSortKey,
        },
        serialNumber: serialNumber,
      });

      await mutateWithToast(promise, () => {}, {
        pending: 'Guardando descarga...',
        success: 'Descarga guardada correctamente.',
        error: 'Error al guardar la descarga.',
      });
    }

    // Update this descarga to the next order
    const promise = onUpdateUnloadGuide({
      descarga: {
        ...descarga,
        sortKey: nextOrder,
      },
      serialNumber: serialNumber,
    });

    await mutateWithToast(promise, () => {}, {
      pending: 'Guardando descarga...',
      success: 'Descarga guardada correctamente.',
      error: 'Error al guardar la descarga.',
    });
  }, [descarga, descargas, hasSortKey, onUpdateUnloadGuide, serialNumber, sortKeys?.length, thisSortKey]);

  const onSortDown = useCallback(async () => {
    // The next order for this descarga is: if it has a sort key then it's the next sort key, otherwise it's sortKeys?.length + 1
    const nextOrder = hasSortKey ? thisSortKey + 1 : sortKeys?.length + 1;

    // If there's a sort key that's the same as the next order, then we need to update its descarga to the next order
    const nextOrderDescarga = descargas.find((descarga) => descarga.sortKey === nextOrder);
    if (nextOrderDescarga) {
      // If nextOrderDescarga has a 'lots' property, remove it
      const { lots, ...nextOrderDescagaProps } = nextOrderDescarga;
      const promise = onUpdateUnloadGuide({
        descarga: {
          ...nextOrderDescagaProps,
          sortKey: thisSortKey,
        },
        serialNumber: serialNumber,
      });

      await mutateWithToast(promise, () => {}, {
        pending: 'Guardando descarga...',
        success: 'Descarga guardada correctamente.',
        error: 'Error al guardar la descarga.',
      });
    }

    // If nextOrderDescarga has a 'lots' property, remove it
    const { lots: targetDescargaLots, ...targetDescargaProps } = descarga;
    // Update this descarga to the next order
    const promise = onUpdateUnloadGuide({
      descarga: {
        ...targetDescargaProps,
        sortKey: nextOrder,
      },
      serialNumber: serialNumber,
    });

    await mutateWithToast(promise, () => {}, {
      pending: 'Guardando descarga...',
      success: 'Descarga guardada correctamente.',
      error: 'Error al guardar la descarga.',
    });
  }, [descarga, descargas, hasSortKey, onUpdateUnloadGuide, serialNumber, sortKeys?.length, thisSortKey]);

  return (
    <div className="flex items-center">
      <IconButton
        aria-label="ordenar-arriba"
        size="small"
        onClick={onSortUp}
        disabled={!canSortUp}
        className={canSortUp ? '' : 'opacity-50'}
      >
        <ArrowUpwardIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="ordenar-abajo"
        size="small"
        onClick={onSortDown}
        disabled={!canSortDown}
        className={canSortDown ? '' : 'opacity-50'}
      >
        <ArrowDownwardIcon fontSize="inherit" />
      </IconButton>
      <h3 className="text-lg font-medium leading-6 !ml-4">Orden: {hasSortKey ? thisSortKey : '-'}</h3>
    </div>
  );
}
