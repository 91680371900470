import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectDeviceSelectedSerialNumber } from 'store/devices/devicesSlice';
import { useUpdateRecetaMutation } from 'store/recetas/recetasApi';
import { Recipe } from 'utils/device/event/diet';
import { mutateWithToast } from 'utils/helpers/async/toast.helpers';

const MIN_SORT_KEY = 1;

export function RecetaOrden({ receta, recetas }: { receta: Recipe; recetas: Recipe[] }) {
  const serialNumber = useSelector(selectDeviceSelectedSerialNumber) || '';

  const [onUpdateReceta] = useUpdateRecetaMutation();

  // Get all sort keys
  const sortKeys = recetas.filter((receta) => !!receta.sortKey).map((receta) => receta.sortKey || 1);
  // Has sort key?
  const hasSortKey = receta.sortKey !== undefined;
  // Get this receta sort key
  const thisSortKey = receta.sortKey || 0;

  // Can sort up if there are no sort keys or if it has no sort key or if it's greater than the minimum sort key
  const canSortUp = !sortKeys?.length || !hasSortKey || thisSortKey > MIN_SORT_KEY;
  // Can sort down if there are no sort keys or if it has no sort key or if it's less than the maximum sort key
  const canSortDown = !sortKeys?.length || !hasSortKey || thisSortKey < Math.max(...sortKeys);

  const onSortUp = useCallback(async () => {
    // The next order for this receta is: if it has a sort key then it's the previous sort key, otherwise it's sortKeys?.length + 1
    const nextOrder = hasSortKey ? thisSortKey - 1 : sortKeys?.length + 1;

    // If there's a sort key that's the same as the next order, then we need to update its receta to the next order
    const nextOrderReceta = recetas.find((receta) => receta.sortKey === nextOrder);
    if (nextOrderReceta) {
      const promise = onUpdateReceta({
        receta: {
          ...nextOrderReceta,
          sortKey: thisSortKey,
        },
        serialNumber: serialNumber,
      });

      await mutateWithToast(promise, () => {}, {
        pending: 'Guardando receta...',
        success: 'Receta guardada correctamente.',
        error: 'Error al guardar la receta.',
      });
    }

    // Update this receta to the next order
    const promise = onUpdateReceta({
      receta: {
        ...receta,
        sortKey: nextOrder,
      },
      serialNumber: serialNumber,
    });

    await mutateWithToast(promise, () => {}, {
      pending: 'Guardando receta...',
      success: 'Receta guardada correctamente.',
      error: 'Error al guardar la receta.',
    });
  }, [receta, recetas, hasSortKey, onUpdateReceta, serialNumber, sortKeys?.length, thisSortKey]);

  const onSortDown = useCallback(async () => {
    // The next order for this receta is: if it has a sort key then it's the next sort key, otherwise it's sortKeys?.length + 1
    const nextOrder = hasSortKey ? thisSortKey + 1 : sortKeys?.length + 1;

    // If there's a sort key that's the same as the next order, then we need to update its receta to the next order
    const nextOrderReceta = recetas.find((receta) => receta.sortKey === nextOrder);
    if (nextOrderReceta) {
      const promise = onUpdateReceta({
        receta: {
          ...nextOrderReceta,
          sortKey: thisSortKey,
        },
        serialNumber: serialNumber,
      });

      await mutateWithToast(promise, () => {}, {
        pending: 'Guardando receta...',
        success: 'Receta guardada correctamente.',
        error: 'Error al guardar la receta.',
      });
    }

    // Update this receta to the next order
    const promise = onUpdateReceta({
      receta: {
        ...receta,
        sortKey: nextOrder,
      },
      serialNumber: serialNumber,
    });

    await mutateWithToast(promise, () => {}, {
      pending: 'Guardando receta...',
      success: 'Receta guardada correctamente.',
      error: 'Error al guardar la receta.',
    });
  }, [receta, recetas, hasSortKey, onUpdateReceta, serialNumber, sortKeys?.length, thisSortKey]);

  return (
    <div className="flex items-center">
      <IconButton
        aria-label="ordenar-arriba"
        size="small"
        onClick={onSortUp}
        disabled={!canSortUp}
        className={canSortUp ? '' : 'opacity-50'}
      >
        <ArrowUpwardIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        aria-label="ordenar-abajo"
        size="small"
        onClick={onSortDown}
        disabled={!canSortDown}
        className={canSortDown ? '' : 'opacity-50'}
      >
        <ArrowDownwardIcon fontSize="inherit" />
      </IconButton>
      <h3 className="text-lg font-medium leading-6 !ml-4">Orden: {hasSortKey ? thisSortKey : '-'}</h3>
    </div>
  );
}
